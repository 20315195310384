import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9d7e6f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "group__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupsKeys, (groupName) => {
    return (_openBlock(), _createElementBlock("div", {
      key: groupName,
      class: "group"
    }, [
      (groupName !== 'UNNAMED')
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(groupName.replaceAll('_', ' ')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups[groupName], (item) => {
          return _renderSlot(_ctx.$slots, "default", {
            key: item[_ctx.itemIdxProp],
            item: item
          }, undefined, true)
        }), 128))
      ], 16)
    ]))
  }), 128))
}