import { clone, setFromObjArrByProp } from '@/utils/object'

export type GenericAccessStatus = 'public' | 'hidden' | 'invalid'

export interface GenericAccess {
  key: string
  tech?: string
  collection?: string
  model?: string
  id?: string
  name?: string
  displayName?: string
  status?: GenericAccessStatus
}

export const AccessCategories = ['voices', 'backgrounds', 'actors'] as const
export type AccessCategory = typeof AccessCategories[number]

export type AccessDemo = string

export interface AccessItem {
  demo: AccessDemo
  voices?: GenericAccess[]
  backgrounds?: GenericAccess[]
  actors?: GenericAccess[]
}

export type AccessList = AccessItem[]

export interface VoiceFilters {
  tts?: boolean
  vc?: boolean
  livevc?: boolean
}

export interface DemoToAccessCategory {
  voices?: VoiceFilters
  backgrounds?: Record<string, never> // empty, as only voices have filters implemented
  actors?: Record<string, never>
}

export interface AccessMetadata {
  demos: DemoToAccessCategory
  voices: GenericAccess[]
  backgrounds: GenericAccess[]
  actors: GenericAccess[]
}

export function validateAccess(access: AccessList): true | string[] {
  const errors = []

  if (access.length <= 0) {
    errors.push('You must grant access to at least one demo.')
  }

  return errors.length ? errors : true
}

// this function merges access items from user into access metadata
// entities that will be added in that process don't exist anymore in our system, so we want them visible for admins
// so that they can be manually unattached from the users
export function extendAccessMetadataWithUserAccessData(
  accessMetadata: AccessMetadata,
  userAccessData: AccessList
): AccessMetadata {
  const result = clone(accessMetadata)

  const accessMetadataVoiceKeys = setFromObjArrByProp(result.voices || [], 'key')
  const accessMetadataActorKeys = setFromObjArrByProp(result.actors || [], 'key')
  const accessMetadataBgKeys = setFromObjArrByProp(result.backgrounds || [], 'key')

  for (const item of userAccessData) {
    for (const voice of item.voices || []) {
      if (!accessMetadataVoiceKeys.has(voice.key)) {
        voice.status = 'invalid'
        result.voices.push(voice)
      }
    }
    for (const actor of item.actors || []) {
      if (!accessMetadataActorKeys.has(actor.key)) {
        actor.status = 'invalid'
        result.actors.push(actor)
      }
    }
    for (const bg of item.backgrounds || []) {
      if (!accessMetadataBgKeys.has(bg.key)) {
        bg.status = 'invalid'
        result.backgrounds.push(bg)
      }
    }
  }

  return result
}
