import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_alert = _resolveComponent("va-alert")!

  return (_ctx.isErrored(_ctx.subject))
    ? (_openBlock(), _createBlock(_component_va_alert, {
        key: 0,
        color: "danger"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Could not load the data" + _toDisplayString(_ctx.name ? ' (' + _ctx.name + ')' : '') + ". " + _toDisplayString(_ctx.subject.error), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}