import { AccessList, AccessDemo, validateAccess } from '@/modules/techdemo/model/access.model'

export class AccessTemplate {
  _id?: string
  _rev?: string
  name?: string
  access: AccessList
  availableDemos: AccessDemo[]

  constructor(doc: Partial<AccessTemplate>) {
    this._id = doc._id
    this._rev = doc._rev
    this.name = doc.name
    this.access = doc.access || []
    this.availableDemos = this.access.map((item) => item.demo)
  }
}

export function validateAccessTemplate(accessTemplate: AccessTemplate): true | string[] {
  let errors: string[] = []

  if (!accessTemplate.name) {
    errors.push('Name is required.')
  }

  const accessValidation = validateAccess(accessTemplate.access)

  if (accessValidation !== true) {
    errors = [...errors, ...accessValidation]
  }

  return errors.length > 0 ? errors : true
}
