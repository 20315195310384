import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0849d898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pb-3 pt-3" }
const _hoisted_2 = { class: "visually-hidden" }
const _hoisted_3 = {
  key: 0,
  class: "mt-3 ml-5"
}
const _hoisted_4 = { class: "mb-3 access-category__header" }
const _hoisted_5 = {
  key: 0,
  class: "bulk-select"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_divider = _resolveComponent("va-divider")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_Group = _resolveComponent("Group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_va_divider),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demos, (demo) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: demo }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h4", _hoisted_2, _toDisplayString(demo), 1),
          _createVNode(_component_va_checkbox, {
            onClick: ($event: any) => (_ctx.toggleDemoAccess(demo)),
            "model-value": _ctx.hasDemoAccess(demo),
            label: demo,
            disabled: _ctx.disabled
          }, null, 8, ["onClick", "model-value", "label", "disabled"]),
          (_ctx.accessMetadata.demos && Object.keys(_ctx.accessMetadata.demos[demo]).length && _ctx.hasDemoAccess(demo))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accessMetadata.demos[demo], (filters, cat) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "access-category",
                    key: cat
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h5", null, _toDisplayString(_ctx.capitalizeFirstLetter(cat)), 1),
                      (!_ctx.disabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("button", {
                              class: "bulk-select__button",
                              onClick: ($event: any) => (_ctx.setCheckedInCategory(demo, cat, _ctx.filterMetadata(_ctx.accessMetadata[cat], filters), true))
                            }, " Select all ", 8, _hoisted_6),
                            _createElementVNode("button", {
                              class: "bulk-select__button",
                              onClick: ($event: any) => (_ctx.setCheckedInCategory(demo, cat, _ctx.filterMetadata(_ctx.accessMetadata[cat], filters), false))
                            }, " Deselect all ", 8, _hoisted_7)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Group, {
                        items: _ctx.filterMetadata(_ctx.accessMetadata[cat], filters),
                        "group-by": _ctx.getGroupByParam(_ctx.accessMetadata[cat]),
                        "item-idx-prop": "id",
                        class: "access-checkboxes"
                      }, {
                        default: _withCtx(({ item }) => [
                          _createVNode(_component_va_checkbox, {
                            onClick: ($event: any) => (!_ctx.disabled && _ctx.toggleAccess(demo, cat, item)),
                            "model-value": _ctx.hasAccess(demo, cat, item),
                            label: item.displayName || item.name || item.key || item.id,
                            class: _normalizeClass('access-item-status-' + item.status),
                            disabled: _ctx.disabled
                          }, null, 8, ["onClick", "model-value", "label", "class", "disabled"])
                        ]),
                        _: 2
                      }, 1032, ["items", "group-by"])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_va_divider)
      ], 64))
    }), 128))
  ]))
}