
import { computed, defineComponent, PropType, watch } from 'vue'
import {
  AccessCategory,
  AccessDemo,
  AccessList,
  AccessMetadata,
  GenericAccess,
  VoiceFilters,
} from '@/modules/techdemo/model/access.model'
import { capitalizeFirstLetter } from '@/utils/string'
import { ActorStatus } from '@/modules/ciso/model/asset-actor.model'
import Group from '@/components/UI/Group.vue'

export default defineComponent({
  name: 'AccessEditor',
  components: { Group },
  props: {
    modelValue: {
      type: Object as PropType<AccessList>,
      required: true,
    },
    accessMetadata: {
      type: Object as PropType<AccessMetadata>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const accessList = computed({
      get: () => props.modelValue,
      set: (value: AccessList) => emit('update:modelValue', value),
    })

    const demos = computed(() => Object.keys(props.accessMetadata.demos).sort())

    watch(
      () => props.modelValue,
      (value) => {
        accessList.value = value
      }
    )

    const hasDemoAccess = (demo: AccessDemo) => {
      return Boolean(accessList.value.find((item) => item.demo === demo))
    }

    const getDemoIndex = (demo: AccessDemo): number => {
      return accessList.value.findIndex((item) => item.demo === demo)
    }

    const toggleDemoAccess = (demo: AccessDemo) => {
      if (props.disabled) {
        return
      }

      const demoIndex = getDemoIndex(demo)

      if (demoIndex !== -1) {
        accessList.value.splice(demoIndex, 1)
      } else {
        accessList.value.push({ demo, voices: [], actors: [], backgrounds: [] })
      }
    }

    const getAccess = (demoName: AccessDemo, category: AccessCategory): GenericAccess[] => {
      const demo = accessList.value.find((item) => item.demo === demoName)

      if (!demo) {
        return []
      }

      return demo[category] || []
    }

    const hasAccess = (demoName: AccessDemo, category: AccessCategory, item: GenericAccess) => {
      if (category === 'voices') {
        return Boolean(getAccess(demoName, category)?.find((v) => v.model === item.model && v.id === item.id))
      } else {
        return Boolean(getAccess(demoName, category)?.find((v) => v.key === item.key))
      }
    }

    const toggleAccess = (demoName: AccessDemo, category: AccessCategory, item: GenericAccess) => {
      if (props.disabled) {
        return
      }

      const demoIndex = getDemoIndex(demoName)

      if (demoIndex === -1) {
        return
      }

      const claims = getAccess(demoName, category)

      if (category === 'voices') {
        const claimValueIndex = claims.findIndex((v) => v.model === item.model && v.id === item.id)

        if (claimValueIndex !== -1) {
          claims.splice(claimValueIndex, 1)
        } else {
          claims.push(item)
        }
      } else {
        const key = item.key
        const claimValueIndex = claims.findIndex((v) => v.key === key)

        if (claimValueIndex !== -1) {
          claims.splice(claimValueIndex, 1)
        } else {
          claims.push({ key })
        }
      }

      accessList.value[demoIndex][category] = claims
    }

    const setCheckedInCategory = (
      demoName: AccessDemo,
      category: AccessCategory,
      categoryMetadata: GenericAccess[],
      checkedState: boolean
    ) => {
      if (props.disabled) {
        return
      }

      const demoIndex = getDemoIndex(demoName)

      if (demoIndex === -1) {
        return
      }

      const claims: GenericAccess[] = []

      if (checkedState) {
        for (const { key } of categoryMetadata) {
          claims.push({ key })
        }
      }

      accessList.value[demoIndex][category] = claims
    }

    const filterMetadata = (categoryMetadata: GenericAccess[], filters: VoiceFilters | Record<string, never>) => {
      if (!filters || !Object.keys(filters).length) {
        return categoryMetadata
      }

      let result: Array<GenericAccess> = []
      for (const [key, value] of Object.entries(filters)) {
        if (!value) continue
        result = result.concat(categoryMetadata.filter((v) => v.tech === key))
      }
      return result
    }

    // getGroupByParam gets 'model' for voices and 'collection' for anything else
    const getGroupByParam = (categoryMetadata: GenericAccess[]) => {
      if (Array.isArray(categoryMetadata) && categoryMetadata.length) {
        if (Object.prototype.hasOwnProperty.call(categoryMetadata[0], 'model') && categoryMetadata[0].model) {
          return 'model'
        }
      }
      return 'collection'
    }

    return {
      accessList,
      demos,
      hasDemoAccess,
      toggleDemoAccess,
      hasAccess,
      toggleAccess,
      capitalizeFirstLetter,
      setCheckedInCategory,
      filterMetadata,
      ActorStatus,
      getGroupByParam,
    }
  },
})
