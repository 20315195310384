import { AccessMetadata } from '@/modules/techdemo/model/access.model'
import { simpleAuthorizedQuery } from '@/utils/fetch'
import { sortAlphabeticallyByItemProp } from '@/utils/object'
import { techdemoApiUrl } from '@/modules/techdemo/api/_shared'

export async function getAccessMetadata(): Promise<AccessMetadata> {
  return simpleAuthorizedQuery<AccessMetadata>(techdemoApiUrl('admin/access-metadata')).then((metadata) => {
    for (const key of Object.keys(metadata)) {
      const value = metadata[key as keyof AccessMetadata]

      if (Array.isArray(value) && value.length > 0 && Object.prototype.hasOwnProperty.call(value?.[0], 'displayName')) {
        value.sort(sortAlphabeticallyByItemProp('displayName'))
      }
    }

    return metadata
  })
}
