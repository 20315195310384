
import { defineComponent, PropType } from 'vue'
import { isErrored, LoadingState } from '@/utils/loading-state'

export default defineComponent({
  name: 'DataLoadingError',
  props: {
    subject: {
      type: Object as PropType<LoadingState<unknown>>,
      required: true,
    },
    name: {
      type: String,
    },
  },
  setup() {
    return { isErrored }
  },
})
