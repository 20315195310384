export type LoadingStateLoading = {
  __tag: 'loading'
}

export type SuccessfullyLoadedState<T> = {
  __tag: 'loaded'
  data: T
}

export type FailureLoadedState<E = unknown> = {
  __tag: 'error'
  error: E
}

export type LoadedState<T, E = unknown> = SuccessfullyLoadedState<T> | FailureLoadedState<E>

export const LOADING_STATE: LoadingStateLoading = {
  __tag: 'loading',
}

export type LoadingState<T, E = unknown> = LoadingStateLoading | LoadedState<T, E>

export function isLoading<T>(state: LoadingState<T>): boolean {
  return state.__tag === 'loading'
}

export function isLoaded<T>(state: LoadingState<T>): state is SuccessfullyLoadedState<T> {
  return state.__tag === 'loaded'
}

export function isErrored<T, E = unknown>(state: LoadingState<T>): state is FailureLoadedState<E> {
  return state.__tag === 'error'
}

export function loaded<T>(data: T): LoadedState<T> {
  return {
    __tag: 'loaded',
    data,
  }
}

export function errored<T, E = unknown>(error: E): LoadedState<T, E> {
  return {
    __tag: 'error',
    error,
  }
}
