import { isLoaded, LoadingState } from '@/utils/loading-state'

export function clone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object))
}

type SortFn<T> = (a: T, b: T) => -1 | 0 | 1

export function sortAlphabeticallyByItemProp<T>(prop: keyof T): SortFn<T> {
  return function (a: T, b: T) {
    const _a = typeof a[prop] === 'string' ? (a[prop] as unknown as string).toUpperCase() : a[prop]
    const _b = typeof b[prop] === 'string' ? (b[prop] as unknown as string).toUpperCase() : b[prop]

    return _a < _b ? -1 : _a > _b ? 1 : 0
  }
}

/**
 * @example
 *
 * Given an array of objects such as this:
 * const arr =  [{ key: 1, something: "a" }, { key: 2, something: "B" }];
 *
 * Calling this function like that:
 * setFromObjArrByProp(arr, "key");
 *
 * Will output the following set of values: <1, 2>
 */
export function setFromObjArrByProp<T>(arr: T[], propName: string): Set<string | number> {
  const result = new Set<string | number>()

  for (const item of arr) {
    if (Object.prototype.hasOwnProperty.call(item, propName)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.add(item[propName])
    }
  }

  return result
}

export function sortLoadingDataById<T extends LoadingState<{ id?: number }[]>>(data: T) {
  if (isLoaded(data)) {
    return [...data.data].sort((a, b) => (a?.id || 0) - (b?.id || 0))
  }

  return []
}
