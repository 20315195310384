import { ActorAssetTypeToHash } from '@/modules/ciso/model/asset-actor-file.model'

export enum ActorStatus {
  Hidden = 'hidden',
  Public = 'public',
}

export class AssetActor {
  id?: number
  status: ActorStatus
  name: string
  displayName: string
  displayNameArticle: string
  scale: number
  offsetX: number
  offsetY: number
  assetBackgroundId: number | null
  uploadedAssets: ActorAssetTypeToHash
  assetsBinaryHash: string | null
  assetSlotsCount: number
  isPublishable: boolean
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<AssetActor>) {
    this.id = doc.id || undefined
    this.status = doc.status || ActorStatus.Hidden
    this.name = doc.name || ''
    this.displayName = doc.displayName || ''
    this.displayNameArticle = doc.displayNameArticle || ''
    this.scale = doc.scale ?? 1
    this.offsetX = doc.offsetX ?? 0
    this.offsetY = doc.offsetY ?? 0
    this.assetBackgroundId = doc.assetBackgroundId ?? null
    this.uploadedAssets = doc.uploadedAssets ?? {}
    this.assetsBinaryHash = doc.assetsBinaryHash ?? null
    this.assetSlotsCount = doc.assetSlotsCount ?? 0
    this.isPublishable = Boolean(doc.isPublishable)
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
  }

  get uploadedAssetsCount() {
    return Object.keys(this.uploadedAssets).length
  }

  isPublic(): boolean {
    return this.status === ActorStatus.Public
  }

  hasBinaryBundle(): boolean {
    return Boolean(this.assetsBinaryHash)
  }
}
