
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Group',
  props: {
    items: {
      // @TODO: refactor any to generically typed component (PropType<T[]> instead of PropType<any[]>)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      required: true,
    },
    groupBy: {
      type: String,
      required: true,
    },
    itemIdxProp: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const groups = props.items.reduce((acc, curr) => {
      const groupName = curr[props.groupBy] || 'UNNAMED'
      const prev = acc[groupName] || []
      acc[groupName] = [...prev, curr]

      return acc
    }, {})

    const groupsKeys = computed(() => Object.keys(groups).sort())

    return { groups, groupsKeys }
  },
})
