import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import useGlobalStore from '@/store/global-store'

export async function simpleQuery<T>(url: string, headers: Record<string, string> = {}): Promise<T> {
  return axios
    .get<T>(url, {
      headers,
    })
    .then((r) => r.data)
    .catch(apiErrorCatcher)
}

export function getV2AuthHeaders(): Record<string, string> {
  const result: Record<string, string> = {}

  const { getApiKey } = useGlobalStore()

  const apiKey = getApiKey()

  if (apiKey) {
    result.Authorization = 'Apikey ' + apiKey
  }

  return result
}

export function getAuthHeaders(): Record<string, string> {
  const result: Record<string, string> = {}

  const { getApiKey } = useGlobalStore()

  const apiKey = getApiKey()

  if (apiKey) {
    result.Authorization = apiKey
  }

  return result
}

export async function simpleV2AuthorizedQuery<T>(url: string, headers: Record<string, string> = {}): Promise<T> {
  return simpleQuery(url, { ...headers, ...getV2AuthHeaders(), ...getRequestIdHeaders() })
}

export async function simpleAuthorizedQuery<T>(url: string, headers: Record<string, string> = {}): Promise<T> {
  return simpleQuery(url, { ...headers, ...getAuthHeaders(), ...getRequestIdHeaders() })
}

// @TODO: add this as global middleware
export function getRequestIdHeaders(): Record<string, string> {
  const result: Record<string, string> = {}
  result.RequestId = String(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER))

  return result
}

export async function simpleCharactrQuery<T>(url: string, headers: Record<string, string> = {}): Promise<T> {
  return simpleQuery(url, { ...headers, ...getRequestIdHeaders() })
}
